.payment-card {
    border-radius: 15px;
    text-align: center;
    padding: 20px;
    min-height: 32vh;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: none;
  
    .icon-wrapper {
      margin-bottom: 15px;
      .icon {
        font-size: 2rem;
        color: #6c757d;
      }
    }
  
    h5 {
      font-size: 1.25rem;
      margin-bottom: 15px;
    }
  
    p {
      font-size: 1rem;
      color: #6c757d;
      margin-bottom: 20px;
    }
  
    .btn-primary {
      background-color: #d9534f;
      border-color: #d43f3a;
      &:hover {
        background-color: #c9302c;
        border-color: #ac2925;
      }
    }
    
  }
  
  .card-button {
    margin-top: auto;
    margin-bottom: 5px;
  }