.logo-sidebar {
  width: 70px;
  /* Tamaño del logo */
  height: auto;
  /* Mantiene la proporción del logo */
}

.d-flex {
  display: flex;
  /* Habilita el contenedor flex */
  align-items: center;
  /* Alinea verticalmente el contenido */
  padding: 10px;
  /* Espaciado opcional alrededor del contenedor */
}

.ms-2 {
  margin-left: 10px;
  /* Espaciado entre el logo y el texto */
}

.divider {
  color: #ff0019;
  height: 2px;
  background-color: #ff0019;
  padding: auto;
}

.icons-custom {
  margin-right: 15px;
}
.sidebar-border {
  border-right: 5px solid #C20114; /* Ajusta el grosor y el color según lo necesites */
}
.nav {
  display: flex;
  flex-direction: column;
}
.nav-item {
  margin-bottom: 1rem; /* Ajusta el espaciado entre elementos */
}

.active-link {
  background-color: #C20114; /* El color de fondo que prefieras */
  color: #fff; /* El color del texto que prefieras */

} 
.button-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Alinea los botones al final del sidebar */
  height: 100vh; /* Asegura que el sidebar ocupe toda la altura de la pantalla */
  padding: 20px; /* Espacio interno para que no queden pegados a los bordes */
}

.button-access, .button-access2 {
  display: flex;
  justify-content: space-around; /* Ajusta la separación horizontal entre los botones */
  padding-bottom: 20px; /* Ajusta el espacio inferior para que no queden pegados al borde */}

.sidebar {
  display: flex;
  flex-direction: column;
  height: 95%; /* Asegura que el sidebar ocupe toda la altura de la pantalla */
}

/* sidebar.css */
.active-link {
  background-color: red !important; /* Cambia a un color visible para pruebas */
}
