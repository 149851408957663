/* src/components/LoginForm.css */

.login-container {
    background-image: url('../../img/Login.png');
    background-size: cover;
    background-position: center;
    height: 100vh;
  }
  
  .login-form {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center; }
  .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -220%);
    z-index: 1; /* Ensure the logo is above other elements */
    width: 150px; 
}
.social-bar {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
}

.social-icons {
  font-size: 34px;
  color: #fff;
  margin-top: 35px;
  margin-bottom: 35px;
}
.forgot-password-link {
  color: white;
  text-decoration: none;
}